import { PROJECT_TITLE } from '../constants';

export const Hero = () => {
  return (
    <div className="text-center py-5 bg-indigo-700">
      <h1 className="text-4xl">{PROJECT_TITLE}</h1>
      <span className="text-xs">v{process.env.REACT_APP_VERSION}</span>
    </div>
  );
};
