export const SUBS = [
    { id: 'cityporn', name: 'City' },
    { id: 'foodporn', name: 'Food' },
    { id: 'roomporn', name: 'Room' },
    { id: 'designporn', name: 'Design' },
    { id: 'abandonedporn', name: 'Abandoned' },
    { id: 'earthporn', name: 'Earth' },
    { id: 'spaceporn', name: 'Space' },
    { id: 'beachporn', name: 'Beach' },
    { id: 'battlestations', name: 'Battlestations' },
];

export const ALL_SUBS = [
    {
        name: 'Nature',
        type: 'group',
        // id: 'nature',
        items: [
            { value: 'EarthPorn', name: 'EarthPorn' },
            { value: 'BotanicalPorn', name: 'Option' },
            { value: 'WaterPorn', name: 'Option' },
            { value: 'SeaPorn', name: 'Option' },
            { value: 'SkyPorn', name: 'Option' },
            { value: 'FirePorn', name: 'Option' },
            { value: 'DesertPorn', name: 'Option' },
            { value: 'WinterPorn', name: 'Option' },
            { value: 'AutumnPorn', name: 'Option' },
            { value: 'WeatherPorn', name: 'Option' },
            { value: 'GeologyPorn', name: 'Option' },
            { value: 'SpacePorn', name: 'Option' },
            { value: 'BeachPorn', name: 'Option' },
            { value: 'MushroomPorn', name: 'Option' },
            { value: 'SpringPorn', name: 'Option' },
            { value: 'SummerPorn', name: 'Option' },
            { value: 'LavaPorn', name: 'Option' },
            { value: 'LakePorn', name: 'Option' }
        ]
    },
    // {
    //     name: 'Synthetic',
    //     type: 'group',
    //     id: 'synthetic',
    //     items: [
    //         'CityPorn', 'VillagePorn', 'RuralPorn', 'ArchitecturePorn', 'HousePorn', 'CabinPorn', 'ChurchPorn', 'AbandonedPorn', 'CemeteryPorn', 'InfrastructurePorn', 'MachinePorn', 'CarPorn', 'F1Porn', 'MotorcyclePorn', 'MilitaryPorn', 'GunPorn', 'KnifePorn', 'BoatPorn', 'RidesPorn', 'DestructionPorn', 'ThingsCutInHalfPorn', 'StarshipPorn', 'ToolPorn', 'TechnologyPorn', 'BridgePorn', 'PolicePorn', 'SteamPorn', 'RetailPorn', 'SpaceFlightPorn', 'roadporn', 'drydockporn'
    //     ]
    // },
    // {
    //     name: 'Organic',
    //     type: 'group',
    //     id: 'organic',
    //     items: [
    //         'AnimalPorn', 'HumanPorn', 'EarthlingPorn', 'AdrenalinePorn', 'ClimbingPorn', 'SportsPorn', 'AgriculturePorn', 'TeaPorn', 'BonsaiPorn', 'FoodPorn', 'CulinaryPorn', 'DessertPorn'
    //     ]
    // },
    // {
    //     name: 'Aesthetic',
    //     type: 'group',
    //     id: 'aesthetic',
    //     items: [
    //         'DesignPorn', 'RoomPorn', 'AlbumArtPorn', 'MetalPorn', 'MoviePosterPorn', 'TelevisionPosterPorn', 'ComicBookPorn', 'StreetArtPorn', 'AdPorn', 'ArtPorn', 'FractalPorn', 'InstrumentPorn', 'ExposurePorn', 'MacroPorn', 'MicroPorn', 'GeekPorn', 'MTGPorn', 'GamerPorn', 'PowerWashingPorn', 'AerialPorn', 'OrganizationPorn', 'FashionPorn', 'AVPorn', 'ApocalypsePorn', 'InfraredPorn', 'ViewPorn', 'HellscapePorn', 'sculptureporn'
    //     ]
    // },
    // {
    //     id: 'scholastic',
    //     name: 'Scholastic',
    //     type: 'group',
    //     items: [
    //         'HistoryPorn', 'UniformPorn', 'BookPorn', 'NewsPorn', 'QuotesPorn', 'FuturePorn', 'FossilPorn', 'MegalithPorn', 'ArtefactPorn'
    //     ]
    // }
];

export const PROJECT_TITLE = 'SFW Porn Network';
export const PROJECT_ID = '_sfw_porn';
export const API = process.env.NODE_ENV === 'production'
    ? 'https://us-central1-reddit-backend-5f6ea.cloudfunctions.net/api'
    : 'http://localhost:5001/reddit-backend-5f6ea/us-central1/api';
