import React, { useEffect, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import superagent from 'superagent';
import { ISubreddit } from '../models/subreddit.interface';
import { Categories } from './Categories';
import { SRLWrapper } from 'simple-react-lightbox';
import { API, PROJECT_ID } from '../constants';
import axios from 'axios';

export const ImageList = () => {
  const [posts, setPosts] = useState([] as any[]);
  const [lastId, setLastId] = useState('');
  const [reachedEnd, setReachedEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({ name: '', id: '' });

  useEffect(() => {
    const fetchImages = async () => {
      const result = await axios.get(`${API}/posts`, {
        headers: {
          Id: PROJECT_ID
        }
      });

      setLastId(result.data[result.data.length - 1].id);
      setPosts(result.data);
    };
    fetchImages();
  }, []);

  const fetchMore = async () => {
    setLastId(posts[posts.length - 1].id);

    if (!loading) {
      const result = await superagent.get(`${API}/nextPage?next=${lastId}&category=${selectedCategory.id}`).set({ Id: PROJECT_ID });

      if (result.body && !result.body.length) {
        setReachedEnd(true);
      }

      if (result.body.length) {
        setLastId(result.body[result.body.length - 1].id);
        setPosts(posts.concat(result.body));
      }

      setLoading(false);
    } else {
      setReachedEnd(true);
    }
  };

  const handleSelectedCategory = async (category: ISubreddit) => {
    setSelectedCategory(category);
    const result = await superagent.get(`${API}/posts?category=${category.id}`).set({ Id: PROJECT_ID });

    setLastId(result.body[result.body.length - 1].id);
    setPosts(result.body);
  };

  return (
    <div>
      <Categories selectedCategory={handleSelectedCategory} />

      <div className="px-4 pb-8 mt-10">
        {selectedCategory?.id && <h1 className="text-center text-black font-bold text-4xl mb-8">{selectedCategory.id}</h1>}
        <SRLWrapper>
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1100: 4, 1200: 5, 1450: 6 }}>
            <Masonry gutter={'12px'}>
              {posts.length &&
                posts.map((post: any, i) => (
                  <div key={i} className="my-1 rounded-lg mt-0 overflow-hidden">
                    <img
                      className="border border-slate-200 rounded-lg"
                      src={post.thumbnail || post.source}
                      alt={post.title + ' by u/' + post.author}
                    />
                  </div>
                ))}
            </Masonry>
          </ResponsiveMasonry>
        </SRLWrapper>

        <div className="block text-center my-10">
          {!reachedEnd ? (
            <button
                className="bg-indigo-700 text-white px-5 py-2 rounded-md hover:bg-indigo-900"
                onClick={fetchMore}
                disabled={loading}
            >
              Load more
            </button>
          ) : (
            <p className="text-indigo-900">No more results...</p>
          )}
        </div>
      </div>
    </div>
  );
};
