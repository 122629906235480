import React from 'react';
import './App.css';
import { Hero, ImageList } from './Home';

function App() {
  return (
    <div className="App bg-slate-100">
      <Hero />
      <ImageList />
    </div>
  );
}

export default App;
