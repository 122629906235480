import React, { useState } from 'react';
import { SUBS } from '../constants';
import { ISubreddit } from '../models/subreddit.interface';

export const Categories = ({ selectedCategory }: { selectedCategory: any }) => {
  const [activeCategory, setActiveCategory] = useState({ name: '', id: '' });

  const handleCategory = (sub: ISubreddit) => {
    setActiveCategory(sub);
    selectedCategory(sub);
  };

  return (
    <ul className="text-center bg-indigo-600">
      <li className="inline-block px-2">
        <a className="rounded p-4 text-white hover:underline" href="/">
          All
        </a>
      </li>
      {SUBS.map((sub: ISubreddit, i: number) => (
        <li key={i} className="inline-block px-2">
          <button className={'rounded p-4 text-white hover:underline'} onClick={() => handleCategory(sub)}>
            {sub.name}
          </button>
        </li>
      ))}

      {/* <li>
        <SelectSearch options={ALL_SUBS as any} onChange={(event: any) => handleCategory({ id: event, name: '' })} />
      </li> */}
    </ul>
  );
};
